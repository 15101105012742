<template>
  <div></div>
</template>
<script>
import moment from 'moment';
import { getAllCalendarEvents } from '@/api/Google';
import { getMeetingAttendees, getDatetimeFromEvent } from '@/components/Utils';

export default {
  name: 'Now',
  components: {
    // Meeting,
  },
  props: {
    ignoreIds: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  data() {
    return {
      id: '',
      calendarId: '',
    };
  },
  computed: {
    calendarIds() {
      return this.$store.getters['selectedCalendars'];
    },
    showSolo() {
      return this.$store.getters['preferenceShowSolo'];
    },
    showAllDay() {
      return this.$store.getters['preferenceShowAllDay'];
    },
  },
  methods: {
    getNowEventId: async function (ignoreIds) {
      function sortByDateAsc(a, b) {
        const len = 10; // len of date without time "YYYY-MM-DD"
        // for correct comparison we have to set time to 23:59 for date only so meetings with time are first
        // asc, comparing dates
        const strA = getDatetimeFromEvent(a.event);
        const strB = getDatetimeFromEvent(b.event);
        return (
          (strA.length > len
            ? new Date(strA)
            : new Date(strA).setHours(23, 59, 59, 0)) -
          (strB.length > len
            ? new Date(strB)
            : new Date(strB).setHours(23, 59, 59, 0))
        );
      }

      let events = [];

      const params = {
        timeMin: moment(new Date()).format(), // from now on
      };

      if (!this.$store.getters['preferencesLoaded']) {
        await this.$store.dispatch('fetchPreferences');
      }

      events = await getAllCalendarEvents(this.calendarIds, params);

      // sort events from all the calendars
      events.sort(sortByDateAsc);

      // ALL=F,SOLO=T
      // allday solo event
      const now = new Date();

      for (let i = 0; i < events.length; i++) {
        const endTime = new Date(events[i].event.end.dateTime);
        const attendees = getMeetingAttendees(events[i].event);
        // ignore events ended before now
        if (endTime <= now) continue;

        if (!this.showSolo) {
          if (attendees.length <= 1) continue;
        }
        if (!this.showAllDay) {
          if (events[i].event.start.date) continue;
        }
        if (ignoreIds.length > 0 && ignoreIds.includes(events[i].event.id)) {
          // console.log('ignored id', ignoreIds);
          continue;
        }

        // return id as it is either current or first future
        return { eid: events[i].event.id, cid: events[i].cid };
      }

      return null;
    },
  },
  mounted() {
    this.getNowEventId(this.ignoreIds).then((event) => {
      const { eid, cid } = event || {};
      if (eid) {
        this.$router.replace({
          name: 'meeting',
          params: { id: eid, calendarId: cid },
        });
      } else {
        this.$snack("Oops - we couldn't find any future event!");
        this.$router.push({ name: 'meetings' });
      }
    });
  },
};
</script>
